.App {
  text-align: center;
}

.header {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  position: fixed;
  width: 100%;
  height: 80px;
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header-content {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1200px;
  padding: 20px;
  width: 100%;
}

.body {
  margin: 0;
  padding: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.body-content {
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  width: 100%;
  align-items: flex-start;
  color: #ffffff;
  user-select: none;
}

.game-title {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 30px;
}
.description {
  font-size: 24px;
  margin-bottom: 30px;
}
.start-btn {
  background-color: #ffffff;
  border-radius: 10px;
  color: #000000;
  font-size: 20px;
  font-weight: bold;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}
.start-btn:hover {
  background-color: #d3d3d3;
  color: #000000;
  cursor: pointer;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  text-align: center;
  padding: 10px 0;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}